import SidebarPaletteButton from 'components/CommandPalette/SidebarPaletteButton'
import { useCurrentUser, useLogout } from 'hooks'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { UserAvatar } from 'components/common/Avatars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faSignOut } from '@fortawesome/pro-regular-svg-icons'
import { ROOT_HOSTNAME } from 'lib/helpers'
import {
  NavRightSide,
  IProps as INavRightSideProps
} from './NavRightSide/NavRightSide'
import CommandPalette from 'components/CommandPalette/CommandPalette'
import Typography from 'components/common/Typography'
import Divider from 'components/common/Divider'
import RoundedIconButton from 'components/common/RoundedIconButton'
import { twMerge } from 'tailwind-merge'
import ClickAwayListener from 'components/common/ClickAwayListener'
import { Dialog } from '@headlessui/react'

interface IProps {
  navRightSideProps?: INavRightSideProps
}

export const MobileNav: React.FC<IProps> = ({ navRightSideProps }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [slide, setSlide] = useState(false)

  const { logout } = useLogout()
  const user = useCurrentUser()
  const { displayName, email, id } = user

  useEffect(() => {
    if (isOpen) {
      setSlide(true)
    }
  }, [isOpen])

  useEffect(() => {
    if (!slide) {
      setTimeout(() => setIsOpen(false), 300)
    }
  }, [slide])

  return (
    <>
      <div className="bg-white flex justify-between pt-4 pb-3 px-4">
        <UserAvatar user={user} onClick={() => setIsOpen(true)} />

        <div className="flex gap-4">
          <NavRightSide
            {...navRightSideProps}
            showUserNavMenuDropdown={false}
            redirectAfterLogins
          />
        </div>
      </div>

      <Dialog open={isOpen} onClose={() => setSlide(false)}>
        <ClickAwayListener onClickAway={() => setSlide(false)}>
          <div
            className={twMerge(
              'absolute top-0 left-0 border-gray-200 h-screen flex flex-col bg-white transition-all w-0 overflow-hidden duration-300',
              slide && 'w-[80%] z-3000 border-r shadow-xl'
            )}
          >
            <div className="flex p-2 items-center justify-between mt-2 bg-white z-3000 w-full">
              <Link href={`/profiles/${id}/`}>
                <div
                  className="flex flex-1 items-center"
                  onClick={() => setSlide(false)}
                >
                  <UserAvatar user={user} />

                  <div className="flex-1 px-2 text-left overflow-hidden">
                    {displayName && <Typography>{displayName}</Typography>}
                    {email && <Typography truncate={1}>{email}</Typography>}
                  </div>
                </div>
              </Link>

              <Link href={`${ROOT_HOSTNAME}/settings`}>
                <RoundedIconButton
                  onClick={() => setIsOpen(false)}
                  icon={faCog}
                />
              </Link>
            </div>

            <div className="flex flex-col align-start px-0 py-2 mt-1 mb-3 border-t border-gray-100">
              <Link href={`${ROOT_HOSTNAME}/discover`} passHref>
                <Typography className="p-2">Events</Typography>
              </Link>

              <Link href={`${ROOT_HOSTNAME}/help`} passHref>
                <Typography className="p-2">Help</Typography>
              </Link>

              <Link href={`${ROOT_HOSTNAME}/about`} passHref>
                <Typography className="p-2">About</Typography>
              </Link>

              <Link href={`https://blog.upstreamapp.com`} passHref>
                <Typography className="p-2">Upstream Blog</Typography>
              </Link>

              <Link href={`${ROOT_HOSTNAME}/contact`} passHref>
                <Typography className="p-2">Contact Upstream</Typography>
              </Link>

              <Divider />
              <div
                className="flex p-2 items-center space-x-2"
                onClick={() => logout()}
              >
                <Typography component={'button'}>Logout </Typography>
                <FontAwesomeIcon icon={faSignOut} />
              </div>
            </div>

            <div className="flex flex-col flex-1 overflow-auto min-h-[300px]">
              <CommandPalette button={SidebarPaletteButton} />
            </div>
          </div>
        </ClickAwayListener>
      </Dialog>
    </>
  )
}
