import { useWeb3 } from 'context/Web3Context'
import classes from 'classnames'
import { useCurrentUser, useIsLoggedIn } from 'hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoin, faLink, faUnlink } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { useMemo } from 'react'
import LinkedWalletMenuListItem from './LinkedWalletListItem'
import { sortBy } from 'lodash'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { twMerge } from 'tailwind-merge'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { shortAddress } from 'components/Collectives/Common/EnsResolvedAddress'
import { calculateShortAddress } from 'components/Collectives/Common/EtherscanLink'

function CryptoUserNavMenu() {
  const isLoggedIn = useIsLoggedIn()
  const user = useCurrentUser()
  const { toggleNewWalletModal } = useWeb3()

  const {
    signerAddress,
    openSelectWallet,
    disconnectWallet,
    wallet,
    isWalletLinkedToUser
  } = useWeb3()

  const orderedWallets = useMemo(
    () =>
      user.collectiveUser
        ? sortBy(
            user.collectiveUser,
            x => x.address.toLowerCase() !== signerAddress?.toLowerCase()
          )
        : [],
    [user.collectiveUser, signerAddress]
  )

  if (!isLoggedIn || !user) {
    return null
  } else if (!signerAddress) {
    return (
      <div
        className="relative h-10 px-2 sm:px-3 rounded-full flex justify-center items-center cursor-pointer bg-gray-200 hover:bg-gray-300"
        onClick={() => openSelectWallet()}
      >
        <span className="font-light text-gray-700">{'Connect Wallet'}</span>
      </div>
    )
  }

  return (
    <div className="relative">
      <DropdownMenu
        button={({ open }) => {
          return (
            <div
              className={classes(
                'relative h-10 px-2 sm:px-3 rounded-full flex justify-center items-center cursor-pointer',
                open
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              )}
            >
              <FontAwesomeIcon icon={faCoin} />

              <StyledTooltip
                title={`${
                  !isWalletLinkedToUser ? 'Unlinked wallet' : ''
                } ${signerAddress}`}
                arrow
              >
                <span className={twMerge('font-light ml-2 mr-1 w-24')}>
                  {calculateShortAddress(signerAddress)}
                </span>
              </StyledTooltip>

              <StyledTooltip
                title={
                  !isWalletLinkedToUser ? 'Click to link wallet' : 'Linked'
                }
                arrow
              >
                <div
                  className={twMerge(
                    'flex rounded-md',
                    !isWalletLinkedToUser && 'p-1 hover:bg-gray-400'
                  )}
                  onClick={
                    !isWalletLinkedToUser
                      ? e => {
                          toggleNewWalletModal(true)
                          e.stopPropagation()
                        }
                      : undefined
                  }
                >
                  <FontAwesomeIcon
                    icon={isWalletLinkedToUser ? faLink : faUnlink}
                  />
                </div>
              </StyledTooltip>
            </div>
          )
        }}
        items={[
          {
            label: 'Linked wallet accounts:',
            type: 'header',
            subHeader: `(to switch account, use your ${wallet?.label} wallet)`
          },
          {
            type: 'separator'
          },
          !isWalletLinkedToUser && {
            type: 'button',
            onClick: () => toggleNewWalletModal(true),
            label: `New Wallet (${shortAddress(signerAddress)})`,
            tooltip: `Unlinked wallet ${signerAddress}`,
            icon: faUnlink
          },
          ...orderedWallets.map(collectiveUser => (
            <LinkedWalletMenuListItem
              key={`collective-user-${collectiveUser.id}`}
              collectiveUser={collectiveUser}
              disableRemove={!user.email && user.collectiveUser.length === 1}
            />
          )),
          {
            onClick: () => disconnectWallet(),
            type: 'button',
            label: 'Disconnect Wallet',
            icon: faTimes
          }
        ]}
      />
    </div>
  )
}

export default CryptoUserNavMenu
