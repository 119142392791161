import classes from 'classnames'
import { Switch, Menu } from '@headlessui/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import StyledTooltip from 'components/Tooltip/Tooltip'

export type CheckboxMenuItemProps = {
  type: 'checkbox'
  label: string
  checked: boolean
  onChange: CallbackWithParam<boolean>
  info?: string
  icon?: IconProp
  tooltip?: string
  eventName?: string
}

export default function CheckboxMenuItem(item: CheckboxMenuItemProps) {
  const onChange = e => {
    if (item.eventName) {
      window.analytics.track(item.eventName)
    }
    item.onChange(e)
  }
  return (
    <Menu.Item disabled>
      {({ active }) => (
        <StyledTooltip title={item.tooltip || ''} arrow>
          <div
            className={active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}
          >
            <Switch.Group
              as="div"
              className={classes(
                'flex w-full items-center px-4 py-2 space-x-2 cursor-pointer'
              )}
            >
              <Switch
                as="button"
                checked={item.checked}
                onChange={onChange}
                className={`${
                  item.checked ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
              >
                {({ checked }) => (
                  <span
                    className={`${
                      checked ? 'translate-x-5' : 'translate-x-0'
                    } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                  />
                )}
              </Switch>
              <Switch.Label>
                <p
                  className={classes(
                    'text-sm text-left text-gray-700 cursor-pointer'
                  )}
                >
                  {item.label}
                </p>
                {!!item.info && (
                  <p className="text-xs font-light text-text-secondary">
                    {item.info}
                  </p>
                )}
              </Switch.Label>
            </Switch.Group>
          </div>
        </StyledTooltip>
      )}
    </Menu.Item>
  )
}
