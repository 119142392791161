import cls from 'classnames'
import BaseModal from 'components/modals/BaseModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import React, { useState, useCallback, useEffect } from 'react'
import { useEnrichmentsQuery } from 'graphql/generated'
import NftsSection from './NftsSection'
import UploadSection from './UploadSection'
import { empty } from 'lib/helpers'
import Spinner from 'components/Spinner'
import ChooseAvatarButton from './ChooseAvatarButton'
import { useModalContext } from 'context/ModalContext'
import { useSetPreference } from 'hooks'

export function useAvatarSolicitorModal() {
  const { setModalContent } = useModalContext()
  return useCallback(() => {
    setModalContent(<AvatarSolicitorModal />)
  }, [setModalContent])
}

function AvatarSolicitorModal() {
  const { hideModal } = useModalContext()

  const [tab, setTab] = useState<'NFTs' | 'upload'>('NFTs')
  const [selectedAvatarURI, setSelectedAvatarURI] = useState<Maybe<string>>()
  const [uploadedFile, setUploadedFile] = useState<Maybe<IFile>>(null)

  const setSelectedAvatarURIAndClearUploadedFile = (uri: string) => {
    setSelectedAvatarURI(uri)
    setUploadedFile(null)
  }

  const { data, startPolling, stopPolling, loading } = useEnrichmentsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: resp => {
      if (resp.enrichments?.awaitingEnrichments) {
        startPolling(1000)
      }
    }
  })
  const awaitingEnrichments = data?.enrichments?.awaitingEnrichments
  const hasSuggestions =
    !awaitingEnrichments && !empty(data?.enrichments?.suggestions)

  const nftCount = data?.enrichments?.suggestions.nfts?.length || 0

  // @useQuery#onCompleted only fires once, so we can't
  // call stopPolling() from there...
  useEffect(() => {
    if (!awaitingEnrichments) {
      stopPolling()

      if (nftCount > 0) {
        setTab('NFTs')
      } else {
        setTab('upload')
      }
    }
    // eslint-disable-next-line
  }, [awaitingEnrichments, stopPolling])

  const setPref = useSetPreference()
  useEffect(() => {
    setPref('solicitedAvatar', true)
    // eslint-disable-next-line
  }, [])

  return (
    <BaseModal classNames={'sm:max-w-3xl'}>
      <div className="flex flex-col overflow-hidden h-full">
        <div className="flex flex-col overflow-hidden">
          <div className="relative flex flex-col overflow-hidden justify-center items-center px-6 pt-8 pb-2 space-y-2">
            <div
              className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
              onClick={hideModal}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>

            <div className="pb-2 w-full flex flex-col overflow-hidden">
              <p className="text-center font-medium text-xl pb-2">
                {`Choose an Avatar`}
              </p>

              {!hasSuggestions || loading ? (
                <div className="flex flex-col justify-center items-center pt-10">
                  <Spinner className="pb-2" />
                  <p className="text-center font-light text-sm pb-6">
                    One moment while we look up your avatars...
                  </p>
                </div>
              ) : (
                <>
                  <p className="text-center font-light text-sm pb-2">
                    To speed things up, we use search engines and social
                    networks to suggest info that might be yours.
                  </p>

                  <div className="border-b border-gray-200 mb-2 w-full">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      <a
                        href="#"
                        onClick={() => setTab('NFTs')}
                        className={cls(
                          tab === 'NFTs'
                            ? 'border-blue-500 text-blue-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                          'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                        )}
                      >
                        {`NFTs`}
                        {nftCount > 0 && (
                          <span
                            className={cls(
                              tab === 'NFTs'
                                ? 'bg-blue-100 text-blue-600'
                                : 'bg-gray-100 text-gray-900',
                              'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                            )}
                          >
                            {String(nftCount)}
                          </span>
                        )}
                      </a>

                      <a
                        href="#"
                        onClick={() => setTab('upload')}
                        className={cls(
                          tab === 'upload'
                            ? 'border-blue-500 text-blue-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                          'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                        )}
                      >
                        {`Upload File`}
                      </a>
                    </nav>
                  </div>

                  {tab === 'NFTs' && (
                    <NftsSection
                      selectedURI={selectedAvatarURI}
                      onSelectURI={setSelectedAvatarURIAndClearUploadedFile}
                    />
                  )}

                  {tab === 'upload' && (
                    <UploadSection
                      selectedURI={selectedAvatarURI}
                      onSelectURI={setSelectedAvatarURI}
                      onSelectFile={setUploadedFile}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="bg-gray-50 py-3 px-6 flex flex-row-reverse">
            <ChooseAvatarButton
              uploadedFile={uploadedFile}
              selectedURI={selectedAvatarURI}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

export default AvatarSolicitorModal
