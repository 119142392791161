import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Menu, Transition } from '@headlessui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import { usePopper } from 'react-popper'
import React, { useRef, useState } from 'react'
import { isPresentAndTruthy } from 'lib/helpers'
import ButtonMenuItem, { ButtonMenuItemProps } from './ButtonMenuItem'
import CheckboxMenuItem, { CheckboxMenuItemProps } from './CheckboxMenuItem'
import HeaderMenuItem, { HeaderMenuItemProps } from './HeaderMenuItem'
import SeparatorMenuItem, { SeparatorMenuItemProps } from './SeparatorMenuItem'
import PopperPortal from 'components/PopperPortal'
import { isFunction } from 'lodash'
import { twMerge } from 'tailwind-merge'
import Typography from '../Typography'

export type MenuItemProps = { close?: Callback } & (
  | ButtonMenuItemProps
  | CheckboxMenuItemProps
  | HeaderMenuItemProps
  | SeparatorMenuItemProps
)

type IProps = {
  icon?: IconProp
  iconProps?: Pick<FontAwesomeIconProps, 'size' | 'className'>
  iconPlacement?: 'before' | 'after'
  label?: string
  button?:
    | React.ReactElement
    | (({ open }: { open: boolean }) => React.ReactElement)
  onClick?: Callback
  buttonClassName?: string
  items: (
    | MenuItemProps
    | null
    | undefined
    | false
    | React.ReactElement<HTMLElement>
  )[]
}

function getKeyForMenuItem(item: MenuItemProps, index: number) {
  switch (item.type) {
    case 'button':
    case 'checkbox':
      return `item-${index}`
    case 'header':
      return `header-${index}`
    case 'separator':
      return `separator-${index}`
    default:
      return `unknown-${index}`
  }
}

function MenuItem(item: MenuItemProps) {
  switch (item.type) {
    case 'checkbox':
      return <CheckboxMenuItem {...item} />
    case 'header':
      return <HeaderMenuItem {...item} />
    case 'separator':
      return <SeparatorMenuItem />
    case 'button':
    default:
      return <ButtonMenuItem {...item} />
  }
}

function DropdownMenu({
  icon = faEllipsisV,
  label,
  button,
  onClick,
  buttonClassName,
  items,
  iconProps,
  iconPlacement = 'after'
}: IProps) {
  const [arrowElement, setArrowElement] = useState()
  const referenceElement = useRef(null)
  const popperElement = useRef(null)
  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        { name: 'preventOverflow' }
      ],
      placement: 'bottom-start'
    }
  )

  const filteredItems = items.filter(isPresentAndTruthy)
  if (!filteredItems.length) {
    return null
  }

  const iconOnly = !button && !label
  const iconAndLabel = !button && label
  return (
    <Menu>
      {({ open, close }) => (
        <>
          <Menu.Button
            as={'div'}
            className={twMerge(
              button
                ? 'appearance-none flex'
                : 'hover:bg-gray-200 flex justify-center items-center cursor-pointer focus:outline-none gap-2 rounded-md',
              iconOnly && 'w-7 h-9 rounded-full',
              iconAndLabel && 'px-2 py-1',
              iconPlacement === 'before' && 'flex-row-reverse',
              buttonClassName
            )}
            ref={referenceElement}
            onClick={e => {
              e.stopPropagation()
              onClick?.()
            }}
          >
            {button ? (
              isFunction(button) ? (
                button({ open })
              ) : (
                button
              )
            ) : (
              <>
                {label && (
                  <Typography color="darkGray" size="sm">
                    {label}
                  </Typography>
                )}
                <FontAwesomeIcon icon={icon} {...iconProps} />
              </>
            )}
          </Menu.Button>

          <PopperPortal>
            <div
              ref={popperElement}
              style={{ ...styles.popper, zIndex: 9999 }}
              {...attributes.popper}
            >
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Menu.Items className="max-w-68 mt-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none z-50">
                  <div className="py-1 z-100 relative break-all">
                    {filteredItems.map((item, index) =>
                      React.isValidElement(item) ? (
                        <Menu.Item key={`custom_${index}`}>{item}</Menu.Item>
                      ) : (
                        <MenuItem
                          key={getKeyForMenuItem(item, index)}
                          close={close}
                          {...item}
                        />
                      )
                    )}
                  </div>

                  <div
                    // @ts-ignore
                    ref={setArrowElement}
                    style={{
                      ...styles.arrow,
                      top: 0
                    }}
                  />
                </Menu.Items>
              </Transition>
            </div>
          </PopperPortal>
        </>
      )}
    </Menu>
  )
}

export default DropdownMenu
