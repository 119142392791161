import Image from 'next/image'
import { useCallback, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserVisor } from '@fortawesome/pro-duotone-svg-icons'
import classes from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { noop } from 'lodash'

export interface IProps {
  src: Maybe<string>
  initials?: Maybe<string>
  className?: string
  useImgTag?: boolean
  icon?: IconProp
  onError?: Callback
}

function Thumbnail({
  src,
  initials,
  className,
  useImgTag = false,
  icon,
  onError = noop
}: IProps) {
  const [fallback, setFallback] = useState(false)
  const onErrorHandler = useCallback(() => {
    setFallback(true)
    onError()
  }, [onError])

  useEffect(() => {
    setFallback(false)
  }, [src])

  if (!src || fallback) {
    return (
      <div className="relative">
        <div
          className={classes(
            'flex justify-center items-center bg-gray-400',
            className
          )}
        >
          {initials ? (
            <p className="text-white text-sm m-0 p-0">{initials.charAt(0)}</p>
          ) : (
            <FontAwesomeIcon icon={faUserVisor} color={'white'} />
          )}
        </div>

        {icon && (
          <div className="absolute -bottom-1 -right-1 z-50 bg-blue text-white text-xs flex justify-center items-center w-5 h-5 rounded-full">
            <FontAwesomeIcon icon={icon} size="sm" />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="relative">
      <div className={classes('relative', className)}>
        {useImgTag ? (
          <img
            className={`object-contain`}
            src={src}
            onError={onErrorHandler}
          />
        ) : (
          <Image
            alt="Thumbnail"
            className={`object-contain`}
            src={src}
            fill
            sizes="25vw"
            onError={onErrorHandler}
          />
        )}
      </div>

      {icon && (
        <div className="absolute bottom-2 right-0 z-50 bg-blue text-white text-xs flex justify-center items-center w-5 h-5 rounded-full">
          <FontAwesomeIcon icon={icon} size="sm" />
        </div>
      )}
    </div>
  )
}

export default Thumbnail
