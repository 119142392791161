import Image from 'next/image'
import { useCurrentUser } from 'hooks'
import Link from 'next/link'
import AppNavLink from './AppNavLink'
import React from 'react'
import { ROOT_HOSTNAME } from 'lib/helpers'
import {
  NavRightSide,
  IProps as INavRightSideProps
} from './NavRightSide/NavRightSide'
import { MobileNav } from './MobileNav'
import Sticky from 'components/Sticky/Sticky'
import { useBreakpoint } from 'hooks/useBreakpoint'

export interface IProps {
  stuckClasses?: string
  navRightSideProps?: INavRightSideProps
}

const MainNav: React.FC<IProps> = ({
  navRightSideProps,
  stuckClasses = 'bg-white shadow-lg'
}) => {
  const { hasCollectives, collectivesActionablesCount } = useCurrentUser()

  return (
    <Sticky
      className="flex justify-between items-center pt-3 pb-3 px-4 z-75"
      stuckClasses={stuckClasses}
    >
      <div className="flex items-center">
        <Link
          className="relative block -mt-1"
          style={{ width: 1187 / 11, height: 314 / 11 }}
          href="/"
        >
          <Image
            src={'/static/new-logo-dark-horizontal@2x.png'}
            alt="Upstream Logo"
            fill
            sizes="25vw"
          />
        </Link>

        <div className="hidden md:block ml-6 space-x-2">
          <AppNavLink label={'Events'} href={`${ROOT_HOSTNAME}/events`} />

          {hasCollectives && (
            <AppNavLink
              label={'DAOs'}
              href={`${ROOT_HOSTNAME}/collectives/dashboard`}
              authed
              badge={
                collectivesActionablesCount
                  ? String(collectivesActionablesCount)
                  : undefined
              }
            />
          )}
        </div>
      </div>

      <NavRightSide {...navRightSideProps} redirectAfterLogins />
    </Sticky>
  )
}

function AppNav(props: IProps) {
  const { isSm } = useBreakpoint()
  return isSm ? <MainNav {...props} /> : <MobileNav />
}

export default AppNav
