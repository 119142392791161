import React from 'react'
import { faHome } from '@fortawesome/pro-regular-svg-icons'
import Link from 'next/link'
import RoundedIconButton from 'components/common/RoundedIconButton'
import StyledTooltip from 'components/Tooltip/Tooltip'
import language from 'services/Language'
import { useCurrentUser, useIsLoggedIn } from 'hooks'
import NavUserIconDropdown from './NavUserIconDropdown'
import CryptoUserNavMenu from '../UserWallet/CryptoUserNavMenu'
import { NavRightSideLoggedOutView } from './NavRightSideLoggedOutView'
import { StyledBadge } from 'components/common/Badge'
import CommandPalette from 'components/CommandPalette/CommandPalette'
import TopNavPaletteButton from 'components/CommandPalette/TopNavPaletteButton'
import { ROOT_HOSTNAME } from 'lib/helpers'

export interface IProps {
  showCryptoNav?: boolean
  showCommandPallet?: boolean
  showLoggedOutMobilePopover?: boolean
  showLoggedOutGetStartedButton?: boolean
  showUserNavMenuDropdown?: boolean
  redirectAfterLogins?: boolean
}

export const NavRightSide: React.FC<IProps> = ({
  showCryptoNav = true,
  showCommandPallet = true,
  showUserNavMenuDropdown = true,
  showLoggedOutMobilePopover = false,
  showLoggedOutGetStartedButton = true,
  redirectAfterLogins = false
}) => {
  const isLoggedIn = useIsLoggedIn()
  const currentUser = useCurrentUser()

  return (
    <div className="flex space-x-2 items-center flex-1 justify-end">
      {isLoggedIn ? (
        <div className="flex items-center space-x-2">
          <Link href={`${ROOT_HOSTNAME}/collectives/dashboard`}>
            <StyledTooltip
              arrow
              title={language.navigation.home_tooltip}
              placement="bottom"
            >
              {currentUser.collectivesActionablesCount ? (
                <StyledBadge
                  content={String(currentUser.collectivesActionablesCount)}
                  color={'red'}
                >
                  <RoundedIconButton icon={faHome} />
                </StyledBadge>
              ) : (
                <RoundedIconButton icon={faHome} />
              )}
            </StyledTooltip>
          </Link>

          {showCommandPallet && <CommandPalette button={TopNavPaletteButton} />}

          {showCryptoNav && <CryptoUserNavMenu />}

          {showUserNavMenuDropdown && <NavUserIconDropdown />}
        </div>
      ) : (
        <NavRightSideLoggedOutView
          showMobilePopover={showLoggedOutMobilePopover}
          showGetStartedButton={showLoggedOutGetStartedButton}
          redirect={redirectAfterLogins}
        />
      )}
    </div>
  )
}
