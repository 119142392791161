import { useOnEvent } from 'context/EventEmitterContext'
import { useControlledInput } from 'hooks'
import { CollectivesQueryResult } from 'graphql/generated'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import cls from 'classnames'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import Spinner from 'components/Spinner'
import useOnKey from 'hooks/useOnKey'
import SidebarPaletteButton from './SidebarPaletteButton'
import TopNavPaletteButton from './TopNavPaletteButton'
import useCollectives from 'hooks/collectives/useCollectives'
import CollectiveListItem from './CollectiveListItem'
import { useCollectiveContext } from 'context/CollectiveContext'

type TCollectiveEdge = CollectivesQueryResult['me']['collectives']['edges'][0]
type TNode = TCollectiveEdge['node']

interface IProps {
  button: typeof SidebarPaletteButton | typeof TopNavPaletteButton
}

export const OPEN_PALETTE_COMMAND = 'open_palette'

export default function CommandPalette({ button }: IProps) {
  const [query, setQuery, clearQuery] = useControlledInput('')
  const [open, setOpen] = useState(false)
  const [redirectingTo, setRedirectingTo] = useState<Maybe<TNode>>()
  const openPalette = useCallback(() => setOpen(true), [])

  const { collective: currentCollective } = useCollectiveContext()
  const { edges: collectiveEdges, loading: loadingCollectives } =
    useCollectives()

  const collectives: TCollectiveEdge[] = useMemo(
    () =>
      collectiveEdges
        ?.filter(edge =>
          edge.node.name.toLowerCase().includes(query.toLowerCase())
        )
        .slice(0, 20) || [],
    [collectiveEdges, query]
  )

  const showCurrentCollective = !redirectingTo && !query && !!currentCollective
  const showResultsBox =
    showCurrentCollective || collectives.length > 0 || !!redirectingTo
  const showSearchResults = !redirectingTo
  const showCollectivesHeader = showCurrentCollective && collectives.length > 0
  const showLoading = !redirectingTo && loadingCollectives && !showResultsBox
  const showEmpty =
    !redirectingTo && !!query && !loadingCollectives && collectives.length === 0

  const { push } = useRouter()
  const getUrlForItem = useCallback((node: TNode) => {
    if (node.__typename === 'Collective') {
      return node.publicUrl
    }

    return '#'
  }, [])
  const onChange = useCallback(
    (node: TNode) => {
      setRedirectingTo(node)
      push(getUrlForItem(node))
    },
    [push, getUrlForItem]
  )

  useOnKey('Slash', openPalette)
  useOnEvent(OPEN_PALETTE_COMMAND, openPalette)

  return (
    <>
      {button({ openPalette, active: open })}

      <Transition.Root show={open} as={Fragment} afterLeave={clearQuery}>
        <Dialog
          as="div"
          className="fixed inset-0 z-3000 overflow-y-auto p-4 sm:p-6 md:p-20"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/50 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Combobox
              as="div"
              className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
              // @ts-ignore
              onChange={onChange}
            >
              <div className="relative">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <Combobox.Input
                  className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search DAOs..."
                  onChange={setQuery}
                />
              </div>

              {showResultsBox && (
                <Combobox.Options
                  static
                  className="max-h-96 scroll-py-3 overflow-y-auto p-3"
                >
                  {!!redirectingTo && (
                    <div className="py-14 px-6 text-center text-sm sm:px-14">
                      <Spinner />
                      <p className="mt-4 font-medium text-gray-500">
                        Taking you to {redirectingTo.name}...
                      </p>
                    </div>
                  )}

                  {showCurrentCollective && (
                    <>
                      <h2 className="mt-4 mb-2 px-3 text-xs font-medium uppercase text-gray-700">
                        Current DAO
                      </h2>

                      <Combobox.Option
                        key={currentCollective.id}
                        value={currentCollective}
                        className={({ active }) =>
                          cls('select-none rounded-xl', active && 'bg-gray-100')
                        }
                      >
                        {({ active }) => (
                          <CollectiveListItem
                            active={active}
                            hideCurrent={false}
                            collective={currentCollective}
                          />
                        )}
                      </Combobox.Option>
                    </>
                  )}

                  <>
                    {showCollectivesHeader && (
                      <h2 className="mt-4 mb-2 px-3 text-xs font-medium uppercase text-gray-700">
                        DAOs
                      </h2>
                    )}

                    {showSearchResults &&
                      collectives.map(item => (
                        <Combobox.Option
                          key={item.cursor}
                          value={item.node}
                          className={({ active }) =>
                            cls(
                              'select-none rounded-xl',
                              active && 'bg-gray-100'
                            )
                          }
                        >
                          {({ active }) => (
                            <CollectiveListItem
                              active={active}
                              collective={item.node}
                              onClick={clearQuery}
                            />
                          )}
                        </Combobox.Option>
                      ))}
                  </>
                </Combobox.Options>
              )}

              {showLoading && (
                <div className="py-14 px-6 text-center text-sm sm:px-14">
                  <Spinner />
                  <p className="mt-4 font-medium text-gray-500">Loading...</p>
                </div>
              )}

              {showEmpty && (
                <div className="py-14 px-6 text-center text-sm sm:px-14">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    type="outline"
                    name="exclamation-circle"
                    className="mx-auto text-gray-400"
                    size={'2x'}
                  />
                  <p className="mt-4 font-medium text-gray-800">
                    {`Nothing found`}
                  </p>
                  <p className="mt-1 font-light text-gray-500">
                    {`No DAOs found for this query. Please try again.`}
                  </p>
                </div>
              )}
            </Combobox>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  )
}
