import React from 'react'
import { User } from 'graphql/generated'
import { capitalize } from 'lodash'
import { twMerge } from 'tailwind-merge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/pro-solid-svg-icons'

const sizeMap = {
  xLarge: 'h-28 w-28',
  large: 'h-20 w-20',
  medium: 'h-10 w-10',
  small: 'h-6 w-6',
  xSmall: 'h-5 w-5',
  xxSmall: 'h-4 w-4',
  tiny: 'h-2 w-2'
}

export type AvatarProps = {
  size?: keyof typeof sizeMap
  src?: Maybe<string>
  alt?: string
  onClick?: Callback
  className?: HTMLDivElement['className']
  icon?: IconProp
}

export const Avatar: React.FC<AvatarProps> = ({
  size = 'medium',
  src,
  alt = '',
  onClick,
  className,
  icon
}) => {
  const sizeClasses = `${sizeMap[size]} ${sizeMap[size]}`
  if (src) {
    return (
      <img
        onClick={() => onClick && onClick()}
        className={twMerge(
          'inline-flex justify-center items-center rounded-full',
          sizeClasses,
          onClick && 'cursor-pointer',
          className
        )}
        src={src}
        alt={alt}
      />
    )
  }

  if (icon || !alt) {
    return (
      <div
        className={twMerge(
          'inline-flex justify-center items-center overflow-hidden rounded-full bg-gray-400 text-white',
          sizeClasses
        )}
      >
        <FontAwesomeIcon
          icon={!icon ? faUser : icon}
          className="w-full h-full text-xl"
        />
      </div>
    )
  }

  return (
    <span
      className={twMerge(
        'inline-flex items-center justify-center rounded-full bg-gray-500',
        sizeClasses,
        onClick && 'cursor-pointer',
        className
      )}
      onClick={() => onClick && onClick()}
    >
      <span className="text-xs font-medium leading-none text-white">{alt}</span>
    </span>
  )
}

export type UserAvatarProps = {
  user: Maybe<Pick<User, 'thumb' | 'displayName'>>
}

export const UserAvatar: React.FC<
  Omit<AvatarProps, 'src' | 'alt'> & UserAvatarProps
> = ({ user, ...props }) => {
  return (
    <Avatar
      {...props}
      src={user?.thumb}
      alt={capitalize(user?.displayName?.[0] || '')}
    />
  )
}
