import { useApolloClient } from '@apollo/client'
import { merge } from 'lodash'
import { useCallback } from 'react'
import { LanguageQuery } from 'graphql/documents'
import { LanguageQueryResult, LanguageQueryVariables } from 'graphql/generated'
import { LanguageDefinition } from 'languages'
import defaultEnglish from 'languages/en.json'

const language: LanguageDefinition = defaultEnglish

export function useLoadLanguage() {
  const client = useApolloClient()

  return useCallback(
    async (langId = 'en') => {
      try {
        const langResp = await client.query<
          LanguageQueryResult,
          LanguageQueryVariables
        >({
          query: LanguageQuery,
          variables: {
            language: langId
          },
          fetchPolicy: 'network-only'
        })
        const langData = langResp.data.language
        if (!langData) {
          throw new Error(`Empty language response`)
        }

        merge(language, langData)
      } catch (err) {
        console.log(`Failed to load language from server: ${err}`)
      }
    },
    [client]
  )
}

export default language
