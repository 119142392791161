import { inflect } from 'inflection'
import moment, { Duration } from 'moment'

export function formatDuration(duration: Duration) {
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  if (seconds === 0) {
    return `${minutes} Minutes`
  }

  return `${minutes}:${seconds} Minutes`
}

export function humanTime(date: Date, abbrv?: boolean): string {
  return moment(date)
    .locale(abbrv ? 'en-abbrv' : 'en')
    .fromNow()
}

export function calendarTime(date: Date) {
  return moment(date).locale('en').calendar(null, {
    lastWeek: 'MM/DD/YYYY',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'MM/DD/YYYY'
  })
}

export const formatTimeLabel = (
  time: number,
  compact?: boolean,
  concat?: boolean
) => {
  const days = moment.duration(time, 'seconds').days()
  const hours = moment.duration(time, 'seconds').hours()
  const minutes = moment.duration(time, 'seconds').minutes()
  const seconds = moment.duration(time, 'seconds').seconds()

  const daysString =
    days && `${days}${compact ? 'd' : ` ${inflect('days', days)}`}`
  const hoursString =
    hours && `${hours}${compact ? 'h' : ` ${inflect('hours', hours)}`}`
  const minutesString =
    minutes && `${minutes}${compact ? 'm' : ` ${inflect('minutes', minutes)}`}`
  const secondsString =
    seconds && `${seconds}${compact ? 's' : ` ${inflect('seconds', seconds)}`}`

  const parts: string[] = []
  if (daysString) {
    parts.push(daysString)
  }

  if ((!compact || (compact && concat) || !daysString) && hoursString) {
    parts.push(hoursString)
  }

  if (
    (!compact || (compact && concat) || (!daysString && !hoursString)) &&
    minutesString
  ) {
    parts.push(minutesString)
  }

  if (
    (!compact ||
      (compact && concat) ||
      (!daysString && !hoursString && !minutesString)) &&
    secondsString
  ) {
    parts.push(secondsString)
  }

  return parts.filter(Boolean).join(compact ? ' ' : ', ')
}

export function getDateTimeFormat() {
  return 'ddd MMM Do \u00b7 h:mm A'
}

export function getDateFormat() {
  return 'ddd MMM Do'
}

export function getDateAriaLabelFormat() {
  return 'MMMM D, YYYY'
}

export function getFullMonthYearFormat() {
  return 'MMMM YYYY'
}

export function getYearFormat() {
  return 'YYYY'
}

export function getTimeFormat() {
  return 'h:mm A'
}
