import classes from 'classnames'
import { useModalContext } from 'context/ModalContext'
import { MouseEvent, useCallback } from 'react'
import BaseModal from './BaseModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Button from 'components/Button'

interface IProps {
  title: string
  body: React.ReactElement | string
  icon?: IconProp
  cancelLabel?: string
  confirmLabel?: string
  onCancel?: Callback
  onClick: CallbackWithParam<MouseEvent>
  colorScheme?: 'red' | 'green'
}

export function useConfirmPromptModal() {
  const { setModalContent } = useModalContext()
  return useCallback(
    (props: IProps) => {
      setModalContent(<ConfirmPromptModal {...props} />)
    },
    [setModalContent]
  )
}

export function ConfirmPromptModal({
  title,
  body,
  icon = faExclamationTriangle,
  cancelLabel = 'No',
  confirmLabel = 'Yes',
  onCancel: cancelCallback,
  colorScheme = 'red',
  onClick
}: IProps) {
  const { hideModal } = useModalContext()
  const onCancel = () => {
    cancelCallback?.()
    hideModal()
  }
  const onConfirm = e => {
    onClick(e)
    hideModal()
  }

  return (
    <BaseModal zIndex={4000}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div
            className={classes(
              'mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
              colorScheme === 'red' && 'bg-red-100',
              colorScheme === 'green' && 'bg-green-100'
            )}
          >
            <FontAwesomeIcon
              icon={icon}
              className={classes(
                'h-6 w-6',
                colorScheme === 'red' && 'text-red-600',
                colorScheme === 'green' && 'text-green-600'
              )}
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {title}
            </h3>

            <div className="mt-2">
              <p className="text-sm text-gray-500">{body}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-3 px-6 flex flex-row-reverse">
        <Button onClick={onConfirm} label={confirmLabel} />
        <Button
          onClick={onCancel}
          color={'white'}
          label={cancelLabel}
          className={'mr-3'}
        />
      </div>
    </BaseModal>
  )
}
