import language from 'services/Language'
import { UserQuery } from 'graphql/documents'
import Button from 'components/Button'
import {
  useUploadAvatarURLMutation,
  useUploadAvatarMutation
} from 'graphql/generated'
import { useCurrentUser, useQueryErrorHandler } from 'hooks'
import useToasts from 'hooks/useToasts'
import { useModalContext } from 'context/ModalContext'

interface IProps {
  selectedURI: Maybe<string>
  uploadedFile: Maybe<IFile>
}

function ChooseAvatarButton({ uploadedFile, selectedURI }: IProps) {
  const { hideModal } = useModalContext()
  const { id } = useCurrentUser()
  const { addToast } = useToasts()

  const onError = useQueryErrorHandler(
    `Failed to select Avatar. Please try again later.`
  )
  const refetchQueries = [
    {
      query: UserQuery,
      variables: {
        id
      }
    }
  ]

  const [uploadAvatar, { loading: loadingAvatar }] = useUploadAvatarMutation({
    refetchQueries,
    onError,
    onCompleted: resp => {
      if (resp.uploadAvatar) {
        addToast(language.profile_settings.avatar_success)
        hideModal()
      }
    }
  })

  const [uploadAvatarUrl, { loading: loadingAvatarUrl }] =
    useUploadAvatarURLMutation({
      refetchQueries,
      onError,
      onCompleted: resp => {
        if (resp.uploadAvatarURL) {
          addToast(language.profile_settings.avatar_success)
          hideModal()
        }
      }
    })

  const loading = loadingAvatar || loadingAvatarUrl
  const chooseAvatar = () => {
    if (uploadedFile) {
      uploadAvatar({
        variables: {
          file: uploadedFile.file
        }
      })
    } else if (selectedURI) {
      uploadAvatarUrl({
        variables: {
          avatarURL: selectedURI
        }
      })
    }
  }

  return (
    <Button
      disabled={!selectedURI || loading}
      onClick={chooseAvatar}
      color={'blue'}
      label={loading ? 'Saving... ' : 'Choose'}
    />
  )
}

export default ChooseAvatarButton
