import cls from 'classnames'

interface IProps {
  openPalette: Callback
  active: boolean
}

function TopNavPaletteButton({ openPalette, active }: IProps) {
  return (
    <div
      className={cls(
        'relative h-10 px-3 rounded-full cursor-pointer group flex items-center',
        'bg-gray-200 hover:bg-gray-300',
        active ? 'bg-blue-600' : 'bg-gray-200'
      )}
      onClick={openPalette}
    >
      <p
        className={cls(
          'text-center text-sm font-light uppercase flex justify-center items-center',
          active ? 'text-gray-300' : 'text-gray-700'
        )}
      >
        <span className="hidden md:block">Search</span>

        <kbd
          className={cls(
            'inline-block h-5 w-5 rounded font-semibold md:border md:ml-2',
            'border-gray-400 group-hover:border-blue-600 group-hover:text-blue-600'
          )}
        >
          {'/'}
        </kbd>
      </p>
    </div>
  )
}

export default TopNavPaletteButton
