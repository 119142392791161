import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  usePrioritizeUserForAssetSyncMutation,
  UserSyncStatus,
  useViewerQuery
} from 'graphql/generated'
import { humanTime } from 'lib/datetime'
import { useCallback, useEffect, useState } from 'react'
import Button from 'components/Button'

type IProps = {
  userId: string
}

const RESYNC_POSTFIX = `Queue a sync`
const SYNCING_POSTFIX = `Syncing`

const AssetSyncStatusSection: React.FC<IProps> = ({ userId }) => {
  let effectiveText

  const { data, startPolling, refetch, stopPolling } = useViewerQuery({})
  const user = data?.me
  const syncStatus = user?.syncStatus

  const [prioritize, { loading }] = usePrioritizeUserForAssetSyncMutation({
    variables: {
      id: userId
    }
  })

  useEffect(() => {
    if (syncStatus === UserSyncStatus.SUCCESS) {
      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncStatus])

  const [isWorking, setIsWorking] = useState(false)

  const isSyncing =
    loading ||
    (syncStatus &&
      [UserSyncStatus.PROCESSING, UserSyncStatus.PRIORITIZED].includes(
        syncStatus
      ))

  const triggerSync = useCallback(async () => {
    setIsWorking(true)

    await prioritize()
    await refetch()

    startPolling(3000)

    // there is a small delay between the time the user is prioritize and
    // when we refetch the status to show the correct thing.
    setTimeout(() => setIsWorking(false), 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isWorking) {
    effectiveText = `Queuing your sync`
  } else if (syncStatus === UserSyncStatus.NEVER || !user?.syncStatusDate) {
    effectiveText = `User assets have not been synced.`
  } else if (syncStatus === UserSyncStatus.FAILED) {
    effectiveText = `Last sync has failed at ${humanTime(user.syncStatusDate)}.`
  } else if (syncStatus === UserSyncStatus.PROCESSING) {
    effectiveText = `Currently syncing NFTs.`
  } else if (syncStatus === UserSyncStatus.PRIORITIZED) {
    effectiveText = `Your NFTs sync is pending.`
  } else if (syncStatus === UserSyncStatus.SUCCESS) {
    effectiveText = `Last synced ${humanTime(user.syncStatusDate)}`
  }

  const processingMode = isSyncing || isWorking

  return (
    <div className={`text-sm font-light text-text-secondary`}>
      {`${effectiveText}`}

      <Button
        color="link"
        onClick={() => triggerSync()}
        size="xs"
        disabled={processingMode}
        showFocusRing={false}
      >
        {`${processingMode ? SYNCING_POSTFIX : RESYNC_POSTFIX} `}
        <FontAwesomeIcon icon={faSyncAlt} spin={processingMode} size={'1x'} />
      </Button>
    </div>
  )
}

export default AssetSyncStatusSection
