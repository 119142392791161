import React from 'react'
import { twMerge } from 'tailwind-merge'

const badgeColors = {
  blue: 'bg-blue-500 text-white',
  gray: 'bg-gray-200 text-gray-900',
  red: 'bg-red-600 text-white',
  yellow: 'bg-yellow-500 text-white',
  lightYellow: 'bg-yellow-200 text-gray-500'
}

const badgePositions = {
  topRight:
    'top-0 right-0 transform translate-x-1/2 -translate-y-1/2 scale-100',
  centerLeft: 'left-0 top-0.5 transform translate-y-1/2',
  centerRight: 'right-0 top-0.5 transform translate-y-1/2'
}

type BadgeProps = {
  color: keyof typeof badgeColors
  content?: string | number
  position?: keyof typeof badgePositions
  invisible?: boolean
  children?: React.ReactNode
  classname?: string
  noPosition?: boolean
}

export const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      color = 'blue',
      content,
      children,
      position = 'topRight',
      invisible,
      classname,
      noPosition
    },
    ref
  ) => (
    <span className={twMerge('relative inline-flex')} ref={ref}>
      {children}
      {!!content && (
        <span
          className={twMerge(
            !noPosition && `absolute ${badgePositions[position]}`,
            `absolute flex px-2 py-0 rounded-full text-xs box-border min-h-[20px] min-w-[20px] items-center content-center justify-center`,
            !!content && invisible && 'hidden',
            badgePositions[position],
            badgeColors[color],
            classname
          )}
        >
          {content}
        </span>
      )}
    </span>
  )
)
Badge.displayName = 'Badge'

export const StyledBadge = Badge
