import { useEffect } from 'react'

function useOnKey(keyCode: string, fn: Callback) {
  useEffect(() => {
    // document doesn't exist in SSR
    if (typeof window === 'undefined' || !document) {
      return
    }

    function onSlash(event: KeyboardEvent) {
      if (event.code === keyCode) {
        if (
          event.target instanceof HTMLElement &&
          (event.target.tagName == 'INPUT' ||
            event.target.tagName == 'SELECT' ||
            event.target.tagName == 'TEXTAREA' ||
            event.target.isContentEditable)
        ) {
          return
        }

        event.preventDefault()
        fn()
      }
    }

    document.addEventListener('keydown', onSlash)

    return () => {
      document.removeEventListener('keydown', onSlash)
    }
  }, [keyCode, fn])
}

export default useOnKey
