import { useIsLoggedIn } from 'hooks'
import AppNav, { IProps as IAppNavProps } from './AppNav'
import MarketingNav from './MarketingNav'

export interface IProps extends IAppNavProps {
  useMarketingNav?: boolean
}

function EffectiveNav({ useMarketingNav, navRightSideProps, ...rest }: IProps) {
  const isLoggedIn = useIsLoggedIn()
  return !isLoggedIn || useMarketingNav ? (
    <MarketingNav navRightSideProps={navRightSideProps} />
  ) : (
    <AppNav navRightSideProps={navRightSideProps} {...rest} />
  )
}

export default EffectiveNav
