import React, { useEffect, useRef, cloneElement } from 'react'

type Props = {
  onClickAway: Function
  children?: React.ReactNode
}
// popper is portalled causing it to trigger the handler.
// We assume that we do not stick a click away listener in a popper portal because it has already a mechanism to close.
function isEventFromPopperRoot(event) {
  const target = event.target
  return target.closest('#popper-root') !== null
}

const ClickAwayListener = React.forwardRef<HTMLElement, Props>(
  ({ children, onClickAway }, ref) => {
    const refInternal = useRef<HTMLElement | null>(null)

    useEffect(() => {
      function handleClickOutside(event) {
        if (
          refInternal.current &&
          !refInternal.current.contains(event.target) &&
          !isEventFromPopperRoot(event)
        ) {
          onClickAway(event)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [onClickAway])

    const updateRef = (el: HTMLElement) => {
      refInternal.current = el

      if (typeof ref === 'function') {
        ref(el)
      } else if (ref) {
        ref.current = el
      }
    }

    // Clone the children element and attach the ref to the cloned element
    const child = React.Children.only(children) as React.ReactElement
    const clonedChild = cloneElement(child, { ref: updateRef })

    return clonedChild
  }
)

ClickAwayListener.displayName = 'ClickAwayListener'

export default ClickAwayListener
