import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { useEnrichmentsQuery, UserSyncStatus } from 'graphql/generated'
import { useControlledInput, useCurrentUser } from 'hooks'
import AvatarItem from './AvatarItem'
import { useEffect, useState } from 'react'
import AssetSyncStatusSection from './AssetSyncStatusSection'

interface IProps {
  selectedURI: Maybe<string>
  onSelectURI: CallbackWithParam<string>
}

function NftsSection({ selectedURI, onSelectURI }: IProps) {
  const [query, setQuery] = useControlledInput('')
  const [limit, setLimit] = useState(50)
  const user = useCurrentUser()

  useEffect(() => {
    setLimit(50)
  }, [query])

  const { data, refetch } = useEnrichmentsQuery({
    fetchPolicy: 'cache-and-network'
  })
  const nfts =
    data?.enrichments?.suggestions.nfts?.filter(nft =>
      query
        ? nft.name.toLowerCase().includes(query.toLowerCase()) ||
          nft.collectionName.toLowerCase().includes(query.toLowerCase())
        : true
    ) || []

  useEffect(() => {
    if (user.syncStatus === UserSyncStatus.SUCCESS) {
      refetch({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.syncStatus])

  if (!nfts.length && !query.length) {
    return (
      <div className="w-full">
        <p className="text-center text-gray-700 font-light pt-10">{`We couldn't find any NFTs in your linked wallets :(`}</p>
      </div>
    )
  }

  return (
    <div className="w-full overflow-hidden flex flex-col">
      <div className="relative mt-4">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FontAwesomeIcon icon={faSearch} className="h-5 w-5 text-gray-400" />
        </div>
        <input
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:text-sm"
          placeholder="Search NFTs by name, collection name, or token ID..."
          type="search"
          onChange={setQuery}
          value={query}
        />
      </div>
      <div className="relative mt-1">
        <AssetSyncStatusSection userId={user.id} />
      </div>
      <div className="mt-4 grid grid-cols-3 gap-y-4 gap-x-4 overflow-auto flex-1 flex flex-col">
        {nfts.slice(0, limit).map(nft => (
          <AvatarItem
            key={`${nft.address}-${nft.tokenId}`}
            url={nft.url}
            selected={nft.url === selectedURI}
            onSelect={() => onSelectURI(nft.url)}
          >
            <div className="flex justify-between">
              <div className="overflow-hidden">
                <p className="text-xs text-gray-500 truncate">
                  {nft.collectionName}
                </p>
                <h3 className="text-sm text-gray-700 truncate">{nft.name}</h3>
              </div>

              <p className="text-xs font-medium text-gray-500 truncate">
                {`#${nft.tokenId}`}
              </p>
            </div>
          </AvatarItem>
        ))}

        {nfts.length > limit && (
          <div
            onClick={() => setLimit(curr => curr + 50)}
            className="group relative cursor-pointer"
          >
            <div className="w-full h-full flex justify-center items-center bg-gray-50 hover:bg-gray-100 rounded-lg">
              <p className="text-gray-500 font-light">Load More</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NftsSection
