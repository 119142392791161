import { useIsLoggedIn } from 'hooks'
import { useRouter } from 'next/router'
import Link from 'next/link'
import React from 'react'
import { StyledBadge } from 'components/common/Badge'
import Button from 'components/Button'
import { twMerge } from 'tailwind-merge'

function AppNavLink({
  label,
  href,
  authed = false,
  badge
}: {
  label: string
  href: string
  authed?: boolean
  badge?: string
}) {
  const isLogggedIn = useIsLoggedIn()

  const { asPath } = useRouter()
  const active = asPath.length > 1 && href.includes(asPath)

  if (authed && !isLogggedIn) {
    return null
  }

  const button = (
    <Button
      color={'textOnly'}
      size="sm"
      showFocusRing={false}
      label={label}
      className={twMerge(
        'rounded-none',
        active && 'border-b-gray-400 border-b-2'
      )}
    />
  )

  return (
    <Link href={href}>
      {badge ? (
        <StyledBadge color={'red'} content={badge}>
          {button}
        </StyledBadge>
      ) : (
        <span>{button}</span>
      )}
    </Link>
  )
}

export default AppNavLink
