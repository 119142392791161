import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import {
  NavRightSide,
  IProps as INavRightSideProps
} from './NavRightSide/NavRightSide'
import { useCurrentUser } from 'hooks'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { useBreakpoint } from 'hooks/useBreakpoint'

interface IProps {
  navRightSideProps?: INavRightSideProps
}

function MarketingNav({ navRightSideProps }: IProps) {
  const { hasCollectives } = useCurrentUser()
  const { isMobile } = useBreakpoint()

  return (
    <header>
      <div className="flex justify-between items-center pt-3 pb-3 px-4 z-75">
        <div className="flex items-center">
          <Link
            href="/"
            className="relative block -mt-1"
            style={
              isMobile
                ? { width: 1187 / 12, height: 314 / 12 }
                : { width: 1187 / 11, height: 314 / 11 }
            }
          >
            <Image
              src={'/static/new-logo-dark-horizontal@2x.png'}
              alt="Upstream Logo"
              fill
              sizes="25vw"
            />
          </Link>
        </div>

        <nav className="hidden md:flex ml-10 space-x-10">
          <Link
            href="/events"
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Events
          </Link>

          <Link
            className="text-base font-medium text-gray-500 hover:text-gray-900"
            href={hasCollectives ? '/collectives/dashboard' : '/collectives'}
          >
            DAOs
          </Link>

          <DropdownMenu
            button={
              <span className="text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer">
                Use Cases{' '}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="xs"
                  style={{ marginLeft: 5 }}
                />
              </span>
            }
            items={[
              {
                label: 'Investment Clubs',
                href: '/investment-dao',
                eventName: 'Nav bar: Investment Club clicked'
              },
              {
                label: 'NFT Projects',
                href: '/nft-dao',
                eventName: 'Nav bar: NFT Projects clicked'
              },
              {
                label: 'Vault DAO',
                href: '/vault',
                eventName: 'Nav bar: Vault DAO clicked'
              }
            ]}
          />

          <Link
            className="text-base font-medium text-gray-500 hover:text-gray-900"
            href="https://blog.upstreamapp.com"
          >
            Blog
          </Link>

          <Link
            className="text-base font-medium text-gray-500 hover:text-gray-900"
            href="/about"
          >
            About
          </Link>

          <Link
            className="text-base font-medium text-gray-500 hover:text-gray-900"
            href="/contact"
          >
            Contact
          </Link>
        </nav>

        <NavRightSide
          {...navRightSideProps}
          showLoggedOutMobilePopover
          showCommandPallet={false}
        />
      </div>
    </header>
  )
}

export default MarketingNav
