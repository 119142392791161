import cls from 'classnames'

interface IProps {
  openPalette: Callback
  active: boolean
}

function SidebarPaletteButton({ openPalette }: IProps) {
  return (
    <div className="flex flex-1 items-end">
      <p
        onClick={openPalette}
        className="text-center text-sm font-light text-gray-700 uppercase flex-1 pt-3 pb-2 border-t border-gray-200 cursor-pointer hover:bg-gray-50 group"
      >
        <span>Switch DAO</span>
        <kbd
          className={cls(
            'inline-block h-5 w-5 rounded border font-semibold ml-2',
            'border-gray-400 text-gray-500 group-hover:border-blue-600 group-hover:text-blue-600'
          )}
        >
          {'/'}
        </kbd>
      </p>
    </div>
  )
}

export default SidebarPaletteButton
