function Divider({ shade = 'light' }: { shade?: 'light' | 'dark' }) {
  return (
    <div
      className={
        shade ? 'border-t border-gray-200' : 'border-t border-gray-400'
      }
    />
  )
}

export default Divider
