import { useState, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps {
  position?: 'top' | 'bottom'
  offset?: number
  className?: string
  stuckClasses?: string
  unstuckClasses?: string
  children?: React.ReactNode
}

function Sticky({
  position = 'top',
  offset = -1,
  className,
  stuckClasses = '',
  unstuckClasses = '',
  children
}: IProps) {
  const [stuck, setStuck] = useState(false)
  const ref = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    const observerParams: IntersectionObserverInit = { threshold: [1] }
    if (offset > -1) {
      observerParams.rootMargin = `-${offset + 1}px 0px 0px 0px`
    }

    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      observerParams
    )
    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [ref, offset])

  return (
    <div
      style={{
        position: 'sticky',
        [position]: offset
      }}
      className={twMerge(className, stuck ? stuckClasses : unstuckClasses)}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default Sticky
