import { Menu } from '@headlessui/react'

export type SeparatorMenuItemProps = {
  type: 'separator'
}

export default function SeparatorMenuItem() {
  return (
    <Menu.Item disabled>
      <div className="border-t border-gray-100 flex w-full items-center px-4 my-1" />
    </Menu.Item>
  )
}
