import StyledTooltip from 'components/Tooltip/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { useWeb3 } from 'context/Web3Context'
import EnsResolvedAddress from 'components/Collectives/Common/EnsResolvedAddress'
import { CollectiveUser, useUnlinkWalletMutation } from 'graphql/generated'
import { useConfirmPromptModal } from 'components/modals/ConfirmPromptModal'
import React, { useCallback } from 'react'
import Button from 'components/Button'
import { useQueryErrorHandler } from 'hooks'
import useToasts from 'hooks/useToasts'
import { useEventEmitterContext } from 'context/EventEmitterContext'
import { faLink } from '@fortawesome/pro-solid-svg-icons'

interface IProps {
  collectiveUser: Pick<CollectiveUser, 'address'>
  disableRemove?: boolean
}

const LinkedWalletMenuListItem = React.forwardRef<HTMLDivElement, IProps>(
  ({ collectiveUser, disableRemove }, ref) => {
    const { signerAddress, disconnectWallet } = useWeb3()
    const isCurrentWallet =
      signerAddress?.toLowerCase() === collectiveUser.address.toLowerCase()
    const shortAddress = `${collectiveUser.address.substring(
      0,
      4
    )}...${collectiveUser.address.slice(-4)}`

    const { addToast } = useToasts()

    const onError = useQueryErrorHandler(
      'Failed to unlink wallet. Please try again later.'
    )
    const { emitter } = useEventEmitterContext()
    const [unlinkWallet] = useUnlinkWalletMutation({
      variables: {
        address: collectiveUser.address
      },
      onError,
      onCompleted: async () => {
        addToast(`${shortAddress} has been unlinked`)
        if (isCurrentWallet) {
          disconnectWallet()
          emitter.emit('LINKED_ACCOUNT_CHANGED')
        }
      }
    })
    const confirm = useConfirmPromptModal()
    const confirmUnlink = useCallback(
      () =>
        confirm({
          title: 'Unlink Wallet?',
          body: `You're about to unlink ${shortAddress}. Are you sure you'd like to unlink this wallet from your Upstream Account?`,
          onClick: () => {
            unlinkWallet()
          }
        }),
      [confirm, shortAddress, unlinkWallet]
    )

    return (
      <div
        ref={ref}
        className={`text-gray-700 flex flex-col justify-between px-4 text-sm leading-5 text-left hover:bg-gray-100`}
      >
        <div className="flex flex-row items-center space-x-3 group cursor-pointer">
          <StyledTooltip
            title={isCurrentWallet ? 'Current linked wallet' : 'Linked wallet'}
          >
            <div className="w-4">
              {
                <FontAwesomeIcon
                  icon={isCurrentWallet ? faArrowRight : faLink}
                />
              }
            </div>
          </StyledTooltip>
          <StyledTooltip arrow title={collectiveUser.address}>
            <p className="text-sm font-medium leading-5 text-gray-900 truncate overflow-ellipsis py-2.5">
              <EnsResolvedAddress address={collectiveUser.address} />
            </p>
          </StyledTooltip>

          {!disableRemove && (
            <div className="hidden group-hover:block">
              <StyledTooltip arrow title={`Unlink linked wallet`}>
                <Button
                  onClick={confirmUnlink}
                  icon={faTrash}
                  size={'xs'}
                  color={'red'}
                />
              </StyledTooltip>
            </div>
          )}
        </div>
      </div>
    )
  }
)

LinkedWalletMenuListItem.displayName = 'LinkedWalletMenuListItem'

export default LinkedWalletMenuListItem
