import { UserAvatar } from 'components/common/Avatars'
import { useCurrentUser, useIsLoggedIn, useLogout } from 'hooks'
import React from 'react'
import {
  faLifeRing,
  faUserAstronaut,
  faCog
} from '@fortawesome/pro-regular-svg-icons'
import { useAvatarSolicitorModal } from 'components/AvatarSolicitorModal/AvatarSolicitorModal'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons'

function NavUserIconDropdown() {
  const isLoggedIn = useIsLoggedIn()
  const user = useCurrentUser()
  const { logout } = useLogout()
  const chooseAvatar = useAvatarSolicitorModal()

  if (!isLoggedIn || !user) {
    return null
  }
  return (
    <DropdownMenu
      button={
        <div className="cursor-pointer hover:brightness-110">
          <UserAvatar user={user} />
        </div>
      }
      items={[
        {
          type: 'button',
          label: user.displayName || user.email || user.id,
          info: `Signed in as`,
          href: `/profiles/${user.id}`
        },
        {
          type: 'separator'
        },
        {
          type: 'button',
          label: 'Choose Avatar',
          icon: faUserAstronaut,
          onClick: () => chooseAvatar()
        },
        {
          type: 'button',
          label: 'Account Settings',
          icon: faCog,
          href: `/settings`
        },
        {
          type: 'button',
          label: 'Help',
          icon: faLifeRing,
          href: `/help`
        },
        {
          type: 'separator'
        },
        {
          type: 'button',
          label: 'Logout',
          icon: faSignOut,
          onClick: () => logout()
        }
      ]}
    />
  )
}

export default NavUserIconDropdown
