import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { v4 as uuid } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState, ChangeEvent } from 'react'
import AvatarItem from './AvatarItem'

interface IProps {
  selectedURI: Maybe<string>
  onSelectURI: CallbackWithParam<string>
  onSelectFile: CallbackWithParam<IFile>
}

function UploadSection({ selectedURI, onSelectURI, onSelectFile }: IProps) {
  const fileInputRef = useRef<HTMLInputElement>(null!)
  const [uploadedFile, setUploadedFile] = useState<Maybe<IFile>>(null)
  const onFileSelect = (evt: ChangeEvent<HTMLInputElement>) => {
    const origFiles = evt.target.files
    if (!origFiles || !origFiles.length) {
      return
    }

    const file = origFiles[0]
    const fileId = uuid()
    setUploadedFile({
      id: fileId,
      previewUrl: URL.createObjectURL(file),
      file
    })
    onSelectURI(fileId)
    onSelectFile({
      id: fileId,
      previewUrl: URL.createObjectURL(file),
      file
    })

    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)
  }

  return (
    <div className="w-full">
      <div className="mt-6 grid grid-cols-3 gap-y-4 gap-x-4">
        {!!uploadedFile && (
          <AvatarItem
            url={uploadedFile.previewUrl}
            selected={uploadedFile.id === selectedURI}
            onSelect={() => onSelectURI(uploadedFile.id)}
          />
        )}

        <div className="flex justify-center items-center w-full h-full">
          <div
            className="flex justify-center items-center w-28 h-28 rounded-full cursor-pointer bg-gray-100 hover:bg-gray-200 text-gray-500"
            onClick={() => fileInputRef.current?.click()}
          >
            <FontAwesomeIcon icon={faPlus} size={'lg'} />
            <input
              hidden
              multiple
              type="file"
              className="hidden invisible"
              accept="image/*"
              ref={fileInputRef}
              onChange={onFileSelect}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadSection
