import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Menu } from '@headlessui/react'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import { noop } from 'lodash'
import Link from 'next/link'
import { ROOT_HOSTNAME } from 'lib/helpers'
import StyledTooltip from 'components/Tooltip/Tooltip'
import { HTMLAttributeAnchorTarget, MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'

type TBase = {
  type?: 'button'
  label: string | JSX.Element
  info?: string
  icon?: IconProp
  iconPlacement?: 'before' | 'after'
  iconProps?: Pick<FontAwesomeIconProps, 'size' | 'className'>
  tooltip?: string
  eventName?: string
  eventProps?: object
  description?: string
  close?: Callback
  color?: 'default' | 'red' | 'blue'
}
type HrefButton = {
  href: string
  target?: HTMLAttributeAnchorTarget
}
type ClickButton = {
  onClick: CallbackWithParam<MouseEvent>
  disabled?: boolean
  loading?: boolean
}
type TBaseHrefButton = TBase & HrefButton
type TBaseClickButton = TBase & ClickButton

export type ButtonMenuItemProps = TBaseClickButton | TBaseHrefButton

const isHrefButton = (item: ButtonMenuItemProps): item is TBaseHrefButton => {
  return (item as HrefButton).href !== undefined
}

export default function ButtonMenuItem(item: ButtonMenuItemProps) {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (item.eventName) {
      window.analytics.track(item.eventName, item.eventProps)
    }
    isHrefButton(item) ? noop() : item.disabled ? noop() : item.onClick(e)
    item.close?.()
  }

  const isHref = isHrefButton(item)
  const isInternalHref =
    isHref &&
    (item.href.toLowerCase().includes(ROOT_HOSTNAME.toLowerCase()) ||
      item.href.startsWith('/'))

  const iconAfter = item.iconPlacement === 'after'

  const icon = !isHref ? (item.loading ? faSpinner : item.icon) : item.icon

  const content = (
    <div
      className={twMerge(
        'flex items-start w-full items-center px-4 py-2 text-sm leading-5 text-left cursor-pointer ',
        !isHrefButton(item) && item.disabled
          ? 'opacity-50'
          : 'hover:bg-gray-100'
      )}
    >
      {!!icon && !iconAfter && (
        <div className="w-6">
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className={'mr-2 -mt-0.5'}
            {...item.iconProps}
          />
        </div>
      )}

      <span
        className={twMerge(
          'block',
          item.color === 'red' && 'text-red-500',
          item.color === 'blue' && 'text-blue-500'
        )}
      >
        {item.label}
        {!!item.info && (
          <span className="block text-gray-500 text-xs font-light">
            {item.info}
          </span>
        )}
      </span>

      {!!icon && iconAfter && (
        <div className="w-6">
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className={'ml-2 -mt-0.5'}
            {...item.iconProps}
          />
        </div>
      )}
    </div>
  )
  return (
    <Menu.Item>
      {({ active }) => (
        <StyledTooltip title={item.tooltip || ''} arrow>
          <div
            className={active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}
            onClick={onClick}
          >
            {isHref ? (
              isInternalHref ? (
                <Link target={item.target} href={item.href}>
                  {content}
                </Link>
              ) : (
                <a href={item.href} target={item.target}>
                  {content}
                </a>
              )
            ) : (
              content
            )}
          </div>
        </StyledTooltip>
      )}
    </Menu.Item>
  )
}
