import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useCollectiveContext } from 'context/CollectiveContext'
import useEphemeralCryptoAuth from './collectives/useEphemeralCryptoAuth'

function useAuthWalletAndSignUp() {
  const { collective } = useCollectiveContext()

  const { verifyWalletAndLogin } = useEphemeralCryptoAuth()
  const { push } = useRouter()

  const authWalletAndSignup = useCallback(async () => {
    const walletAuth = await verifyWalletAndLogin()
    if (!walletAuth || !collective?.id) {
      return
    }

    const { signature, ephemeralCryptoAuthId, address } = walletAuth
    push(
      `/auth/signup?collectiveId=${collective.id}&signature=${signature}&ephemeralCryptoAuthId=${ephemeralCryptoAuthId}&address=${address}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collective?.id, verifyWalletAndLogin])

  return authWalletAndSignup
}

export default useAuthWalletAndSignUp
