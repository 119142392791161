import cls from 'classnames'

interface IProps {
  url: string
  selected: boolean
  onSelect: Callback
  children?: React.ReactNode
}

function AvatarItem({ url, selected, onSelect, children }: IProps) {
  return (
    <div onClick={onSelect} className="group relative cursor-pointer">
      <div
        className={cls(
          'w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75',
          selected && 'border-3 border-blue'
        )}
      >
        <img
          src={url}
          className="w-full h-full object-center object-cover lg:w-full lg:h-full"
        />
      </div>

      {!!children && <div className="mt-4">{children}</div>}
    </div>
  )
}

export default AvatarItem
