import { Menu } from '@headlessui/react'
import Typography from 'components/common/Typography'

export type HeaderMenuItemProps = {
  type: 'header'
  label: string
  subHeader?: string
}

export default function HeaderMenuItem(item: HeaderMenuItemProps) {
  return (
    <Menu.Item disabled>
      <div className="flex flex-col w-full items-left px-4 py-1 break-word">
        <p className="text-gray-500 text-xs font-medium leading-5 text-left uppercase">
          {item.label}
        </p>
        {item.subHeader && (
          <Typography size="xs" color="lightGray" component={'span'}>
            {item.subHeader}
          </Typography>
        )}
      </div>
    </Menu.Item>
  )
}
