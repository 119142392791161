import cls from 'classnames'
import Thumbnail from 'components/Thumbnail'
import { CollectivesQueryResult } from 'graphql/generated'
import Link from 'next/link'
import { useCollectiveContext } from 'context/CollectiveContext'
import Chip from 'components/common/Chip'

type TCollectiveEdge =
  CollectivesQueryResult['me']['collectives']['edges'][0]['node']

interface IProps {
  collective: TCollectiveEdge
  active: boolean
  onClick?: Callback
  hideCurrent?: boolean
}

function CollectiveListItem({
  collective,
  active,
  onClick,
  hideCurrent = true
}: IProps) {
  const { collective: currentCollective } = useCollectiveContext()

  if (collective.id === currentCollective?.id && hideCurrent) {
    return null
  }

  return (
    <Link
      className="flex items-center cursor-pointer p-4"
      onClick={onClick}
      href={collective.publicUrl}
    >
      <div className="flex h-10 w-10 flex-none items-center justify-center rounded">
        <Thumbnail
          src={collective.thumb}
          initials={collective.name.charAt(0)}
          className={'h-12 w-12 rounded overflow-hidden'}
        />
      </div>

      <div className="flex flex-col justify-center ml-4" translate="no">
        <p
          className={cls(
            'text-sm font-medium',
            active ? 'text-gray-900' : 'text-gray-700'
          )}
        >
          {collective.name}
        </p>

        <div className="flex flex-row items-center space-x-2">
          <p
            className={cls(
              'text-xs font-light',
              active ? 'text-gray-700' : 'text-gray-500'
            )}
          >
            {collective.totalMembers} members
          </p>

          {collective.membership?.privileged && (
            <Chip size={'xs'} label={'signator'} fill="blue" />
          )}

          {!collective.membership?.approved &&
            !!collective.membershipRequest && (
              <Chip size={'xs'} color={'gray'} label={'membership pending'} />
            )}
        </div>
      </div>
    </Link>
  )
}

export default CollectiveListItem
