import React, { Fragment } from 'react'
import {
  faCoins,
  faVideo,
  faBars,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import { Popover, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import Image from 'next/image'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useRouter } from 'next/router'
import Button from 'components/Button'
import useAuthWalletAndSignUp from 'hooks/useAuthWalletAndSignUp'
import TrackableClick from 'components/common/TrackableClick'

interface IProps {
  showMobilePopover: boolean
  showGetStartedButton: boolean
  redirect: boolean
}

const discover = [
  {
    href: '/events',
    name: 'Events',
    description: 'Attend professional events...',
    icon: faVideo
  },
  {
    href: '/collectives',
    name: 'DAOs',
    description: 'Learn about our DAOs...',
    icon: faCoins
  }
]

export const NavRightSideLoggedOutView: React.FC<IProps> = ({
  showMobilePopover,
  redirect,
  showGetStartedButton
}) => {
  const { collective } = useCollectiveContext()
  const authWalletAndSignup = useAuthWalletAndSignUp()

  const { asPath } = useRouter()
  const joinButtonLabel = collective ? `Join ${collective.name}` : `Get Started`

  return (
    <>
      <div className="space-x-4 hidden lg:block">
        <Link href={`/auth/login${redirect ? `?_r=${asPath}` : ''}`} passHref>
          <Button
            label={'Log In'}
            color="textOnly"
            className="whitespace-nowrap text-base py-1 font-medium text-gray-500 hover:text-gray-900"
          />
        </Link>

        {showGetStartedButton && collective && (
          <TrackableClick
            event="Join DAO Header Button Clicked"
            props={{
              collectiveId: collective.id,
              collectiveName: collective.name
            }}
          >
            <Button
              label={joinButtonLabel}
              color={'transparent'}
              rounded="full"
              size="lg"
              onClick={authWalletAndSignup}
            />
          </TrackableClick>
        )}
      </div>

      {showMobilePopover && (
        <Popover className="z-2000 lg:hidden space-x-10">
          {({ open }) => (
            <>
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue">
                <FontAwesomeIcon icon={faBars} className="h-6 w-6" />
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute z-3000 top-16 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5">
                      <div className="flex items-center justify-between">
                        <div
                          className="relative -mt-1"
                          style={{ width: 1187 / 11, height: 314 / 11 }}
                        >
                          <Image
                            src={'/static/new-logo-dark-horizontal@2x.png'}
                            alt="Upstream Logo"
                            fill
                            className="object-cover"
                            sizes="25vw"
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue">
                            <span className="sr-only">Close menu</span>
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </Popover.Button>
                        </div>
                      </div>

                      {!collective && (
                        <div className="mt-6">
                          <nav className="grid grid-cols-1 gap-7">
                            {discover.map(item => (
                              <Link
                                className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                href={item.href}
                                key={item.name}
                              >
                                <div className="shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-blue text-white">
                                  <FontAwesomeIcon
                                    icon={item.icon}
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4 text-base font-medium text-gray-900">
                                  {item.name}
                                </div>
                              </Link>
                            ))}
                          </nav>
                        </div>
                      )}
                    </div>

                    <div className="py-6 px-5">
                      <div className="flex flex-col space-y-10 justify-center">
                        {!collective && (
                          <Link
                            className="text-base font-medium text-gray-900 hover:text-gray-700"
                            href="/about"
                          >
                            About
                          </Link>
                        )}

                        {!collective && (
                          <Link
                            className="text-base font-medium text-gray-900 hover:text-gray-700"
                            href="https://blog.upstreamapp.com"
                          >
                            Blog
                          </Link>
                        )}

                        {!collective && (
                          <Link
                            className="text-base font-medium text-gray-900 hover:text-gray-700"
                            href="/contact"
                          >
                            Contact
                          </Link>
                        )}
                      </div>

                      <div className="mt-6">
                        <Link
                          className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue to-gradient-blue hover:from-blue-dark hover:to-gradientblue-dark"
                          href={
                            collective
                              ? `/auth/signup${redirect ? `?_r=${asPath}` : ''}`
                              : `/collectives/launch`
                          }
                        >
                          {joinButtonLabel}
                        </Link>

                        <p className="mt-6 text-center text-base py-1 font-medium text-gray-500">
                          <Link className="text-gray-900" href="/auth/login">
                            Log In
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
    </>
  )
}
